var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "500", persistent: "", scrollable: "" },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ on, attrs }) {
              return [
                _c(
                  "div",
                  _vm._g(
                    _vm._b({ staticClass: "w-100" }, "div", attrs, false),
                    on
                  ),
                  [_vm._t("default")],
                  2
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-0" },
        [
          _c(
            "v-card-title",
            { staticClass: "pa-0" },
            [
              _c(
                "v-tabs",
                {
                  attrs: { "fixed-tabs": "" },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c("v-tab", [_vm._v("Application settings")]),
                  _c("v-tab", [_vm._v("Add-ons")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "", tile: "" } },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c("v-slider", {
                                staticClass: "mt-10",
                                attrs: {
                                  label: "Inactivity limit",
                                  "thumb-label": "always",
                                  hint: _vm.limitHint,
                                  max: "24",
                                  min: "1",
                                  "persistent-hint": "",
                                  disabled: _vm.isTrial,
                                },
                                model: {
                                  value: _vm.inactivityLimit,
                                  callback: function ($$v) {
                                    _vm.inactivityLimit = $$v
                                  },
                                  expression: "inactivityLimit",
                                },
                              }),
                              _c("v-checkbox", {
                                attrs: {
                                  hint: "In shared mode multiple users can connect to the same application, allowing collaborative editing in certain applications (e.g. JupyterLab >= 4.0). Currently doesn't support querying tables.",
                                  label: "Run in shared mode",
                                  disabled: !_vm.sharedEditable,
                                  "persistent-hint": "",
                                },
                                model: {
                                  value: _vm.isShared,
                                  callback: function ($$v) {
                                    _vm.isShared = $$v
                                  },
                                  expression: "isShared",
                                },
                              }),
                              _c("v-checkbox", {
                                attrs: {
                                  hint: "Persisting session data enables you to retain data in the application's home folder (notably application-specific settings) between restarts. However, not persisting this data will usually come with extra performance benefit.",
                                  label:
                                    "Persist sessions data in home area between restarts",
                                  "persistent-hint": "",
                                },
                                model: {
                                  value: _vm.persistentHomeArea,
                                  callback: function ($$v) {
                                    _vm.persistentHomeArea = $$v
                                  },
                                  expression: "persistentHomeArea",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "", tile: "" } },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "px-1" },
                            [
                              _c(
                                "v-expansion-panels",
                                {
                                  model: {
                                    value: _vm.expandedAddon,
                                    callback: function ($$v) {
                                      _vm.expandedAddon = $$v
                                    },
                                    expression: "expandedAddon",
                                  },
                                },
                                _vm._l(_vm.addons, function (addon, idx) {
                                  return _c(
                                    "v-expansion-panel",
                                    { key: idx },
                                    [
                                      _c(
                                        "v-expansion-panel-header",
                                        {
                                          attrs: { "disable-icon-rotate": "" },
                                          scopedSlots: _vm._u(
                                            [
                                              !addon.enabled
                                                ? {
                                                    key: "actions",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              text: "",
                                                              dense: "",
                                                              color: "primary",
                                                              disabled:
                                                                !addon.editable,
                                                              loading:
                                                                _vm.loaders.includes(
                                                                  addon.name
                                                                ),
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.enableAddon(
                                                                  addon
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v(" Add ")]
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  }
                                                : addon.enabled &&
                                                  !addon.editable
                                                ? {
                                                    key: "actions",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              text: "",
                                                              dense: "",
                                                              color: "error",
                                                              disabled: "",
                                                            },
                                                          },
                                                          [_vm._v("Required")]
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  }
                                                : {
                                                    key: "actions",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              text: "",
                                                              dense: "",
                                                              loading:
                                                                _vm.loaders.includes(
                                                                  addon.name
                                                                ),
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.disableAddon(
                                                                  addon
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("Remove")]
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _vm._v(
                                                " " + _vm._s(addon.name) + " "
                                              ),
                                              _vm.expandedAddon === idx
                                                ? _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("mdi-chevron-up")]
                                                  )
                                                : _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("mdi-chevron-down")]
                                                  ),
                                            ],
                                            1
                                          ),
                                          _c("v-spacer"),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-expansion-panel-content",
                                        [
                                          _c("v-container", [
                                            addon.icon_url
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "float-left mr-3",
                                                  },
                                                  [
                                                    _c("v-img", {
                                                      attrs: {
                                                        "max-width": "4em",
                                                        src: addon.icon_url,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c("div", {
                                              staticClass: "caption",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  addon.description
                                                ),
                                              },
                                            }),
                                            addon.doc_link
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-right mt-2",
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: addon.doc_link,
                                                          target: "_blank",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Go to documentation"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.tab === 1
            ? _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              )
            : _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        color: "primary",
                        loading: _vm.savingSettings,
                        disabled: !_vm.canSave,
                      },
                      on: { click: _vm.saveConfig },
                    },
                    [_vm._v("Save")]
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }